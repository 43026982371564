import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';

export const Skills = () => {
  return (
    <section className="skills" id="skills">
        
        <Container>
          <Row className="align-items-center">
            <Col md = {6} xl = {12}>
              <h1>{"Skills"}</h1>
            </Col>
          </Row>

          <Row> 
            <Col>
              <h2>{"Front-End"}</h2>
              <p>HTML</p>
              <p>CSS</p>
              <p>JavaScript</p>
              <p>React</p>
              <p>jQuery</p>
            </Col>

            <Col>
              <h2>{"Back-End"}</h2>
                <p>Java</p>
                <p>Python</p>
                <p>C++</p>
                <p>C#</p>
                <p>PHP/HBS</p>
                <p>R</p>
                <p>MySQL</p>
            </Col>

            <Col>
              <h2>{"Others"}</h2>
              <p>Git</p>
              <p>React</p>
              <p>MQL5</p>
              <p>AWS/Google Cloud</p>
              <p>Figma</p>
              <p>Jupyter Notebook</p>
              <p>R-Studio</p>
              <p>scikit-learn/numpy/pandas</p>
              <p>Selenium</p>
              <p>Databricks Azure</p>
              <p>DataRobot</p>

            </Col>
            </Row>
        </Container> 
    </section>
  );
}