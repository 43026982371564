import React from 'react';
import { VerticalTimeline, VerticalTimelineElement, } from "react-vertical-timeline-component";
import 'react-vertical-timeline-component/style.min.css';
import WorkIcon from '@mui/icons-material/Work';
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';

export const Experience = () => {
    return (
        <section className='banner' id="experience">
            <Container>
                <Row className="align-items-center">
                    <Col xl = {12}>
                        <h1>{"Experience"}</h1>
                    </Col>
                </Row>
            </Container>
        <VerticalTimeline>
            <VerticalTimelineElement
            className="vertical-timeline-element--work"
            contentStyle={{ color: '#fff' }}
            contentArrowStyle={{ borderRight: '7px solid  rgb(33, 150, 243)' }}
            date="June 2023 - September 2023"
            iconStyle={{ background: 'rgb(33, 150, 243)', color: '#fff' }}
            icon={<WorkIcon />}
            >
            <h3 className="vertical-timeline-element-title" style={{ color: 'black' }}>Daimler Truck</h3>
            <h4 className="vertical-timeline-element-subtitle" style={{ color: 'black' }}>Portland, OR</h4>
            <p>
                Data Science Intern
            </p>
            </VerticalTimelineElement>

            <VerticalTimelineElement
            className="vertical-timeline-element--work"
            date="January 2023 - May 2023"
            contentArrowStyle={{ borderRight: '7px solid  rgb(33, 150, 243)' }}
            iconStyle={{ background: 'rgb(33, 150, 243)', color: '#fff' }}
            icon={<WorkIcon />}
            >
            <h3 className="vertical-timeline-element-title" style={{ color: 'black' }}>University of Portland</h3>
            <h4 className="vertical-timeline-element-subtitle" style={{ color: 'black' }}>Portland, OR</h4>
            <p>
                Undergraduate Researcher
            </p>
            </VerticalTimelineElement>

            <VerticalTimelineElement
            className="vertical-timeline-element--work"
            date="January 2023 - May 2023"
            contentArrowStyle={{ borderRight: '7px solid  rgb(33, 150, 243)' }}
            iconStyle={{ background: 'rgb(33, 150, 243)', color: '#fff' }}
            icon={<WorkIcon />}
            >
            <h3 className="vertical-timeline-element-title" style={{ color: 'black' }}>Backus AgriLabs</h3>
            <h4 className="vertical-timeline-element-subtitle" style={{ color: 'black' }}>Portland, OR</h4>
            <p>
                SWE Freelance
            </p>
            </VerticalTimelineElement>
        
        </VerticalTimeline>
      </section>

    );
}