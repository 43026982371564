import Container from 'react-bootstrap/Container';
import Nav from 'react-bootstrap/Nav';
import Navbar from 'react-bootstrap/Navbar';
import { useState, useEffect } from 'react';
import navIcon1 from '../assets/img/nav-icon1.svg';
import GithubIcon from '../assets/img/github-mark.svg';
import emailIcon from '../assets/img/email.svg';
import resumeIcon from '../assets/img/resumeIcon.svg';
import { BrowserRouter as Router } from "react-router-dom";
import { HashLink } from 'react-router-hash-link';




export const NavBar = () => {
    const [activeLink, setActiveLink] = useState('home');
    const [scrolled, setScrolled] = useState(false);

    useEffect(() => { 
        const onScroll = () => {
            if (window.scrollY > 50) {
                setScrolled(true);
            } else {
                setScrolled(false);
            }
        }

        window.addEventListener("scroll", onScroll)
        return () => window.removeEventListener("scroll", onScroll)
    }, [])

    const onUpdateActiveLink = (value) => {
        setActiveLink(value);
    }

    return (
      <Router>
        <Navbar expand="lg" className={scrolled ? "scrolled": ""}>
          <Container>
            <Navbar.Brand href="home" style={{ color: 'white' }}>mnguy.dev</Navbar.Brand>
            <Navbar.Toggle aria-controls="basic-navbar-nav" />
                <span className="navbar-toggler-icon"></span>

            <Navbar.Collapse id="basic-navbar-nav">
              <Nav className="ms-auto">
                <Nav.Link href="#home" className={activeLink === 'home' ? 'active navbar-link' : 'navbar-link'} onClick={() => onUpdateActiveLink('home')}>Home</Nav.Link>
                <Nav.Link href="#about" className={activeLink === 'about' ? 'active navbar-link' : 'navbar-link'} onClick={() => onUpdateActiveLink('about')}>About Me</Nav.Link>
                <Nav.Link href="#skills" className={activeLink === 'skills' ? 'active navbar-link' : 'navbar-link'} onClick={() => onUpdateActiveLink('skills')}>Skills</Nav.Link>
                <Nav.Link href="#experience" className={activeLink === 'experience' ? 'active navbar-link' : 'navbar-link'} onClick={() => onUpdateActiveLink('experience')}>Experience</Nav.Link>
                <Nav.Link href="#contact" className={activeLink === 'contact' ? 'active navbar-link' : 'navbar-link'} onClick={() => onUpdateActiveLink('contact')}>Contact</Nav.Link>
              </Nav>
              <span className="navbar-text">
                <div className='social-icon'>
                    <a href="https://www.linkedin.com/in/milton-nguy-7a8363233/" target="_blank"><img src={navIcon1} alt="" /></a>
                    <a href="mailto:milton.nguy@outlook.com" target="_blank"><img src={emailIcon} alt="" /></a>
                    <a href="https://github.com/mnguytwofive" target="_blank"><img src={GithubIcon} alt="" /></a>
                </div>
              </span>
            </Navbar.Collapse>
          </Container>
        </Navbar>
      </Router>
      );
}