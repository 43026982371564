import { Container, Row, Col } from "react-bootstrap";
import profileIcon from '../assets/img/profile.jpg'

export const About = () => {


    return (
        <section className="banner" id="about">
            <Container>
                <Row className="align-items-center">
                    
                    <Col xs = {12} md = {6} xl = {12}>
                        <h1>{"About Me"}</h1>
                        <p> My name is Milton Nguy. I am interested in probability, machine learning, 
                            cloud computing technology, and innovation. Fascinated by the potential 
                            of artificial intelligence and its impact in our world, I spend my days 
                            delving into the intricacies of machine learning algorithms to explore ways to 
                            use them in my everyday life through the creation of private projects to 
                            satisfy my curiosities. I spend my days delving deep into the 
                            CS field in pursuit of knowledge and stay up-to-date with latest technological 
                            breakthroughs that impact our ecosystem and economy. From computer vision to natural
                            language processing, I am driven by the possibilities that emerge when data and 
                            algorithms converge. I am interested in any roles relating to the computer science
                            field in pursuit of knowledge to gain personal growth and see if there are job roles 
                            that I might've overlooked but in fact was enjoyable!</p>
                    </Col>
                    <Col >
                        <div class="ratio ratio-16x9">
                            <iframe src="https://www.youtube.com/embed/ng1_dsp8iFs" title="Portfolio Video" allowfullscreen></iframe>
                        </div>
                    </Col>
                </Row>
            </Container>

        </section>
    )
}