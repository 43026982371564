import { useState, useEffect } from "react";
import { Container, Row, Col } from "react-bootstrap";
import headerImg from "../assets/img/cat.png";
import { ArrowRightCircle } from 'react-bootstrap-icons';
import Resume from '../assets/Milton_Nguy_Resume.pdf'
import profileIcon from '../assets/img/profile.jpg'


export const Banner = () => {


    return (
        <section className="banner" id="home">
            <Container>
                <Row className="align-items-center">
                    <Col xs = {12} md = {6} xl = {9}>
                        <span className="tagline">Welcome to my Portfolio</span>
                        <h1>{"Hi! My Name is Milton Nguy"}</h1>
                        <p style={{textDecoration: 'none'}}> I am an undergraduate student at the University of Portland studying computer science. Check out my resume 
                            <a> </a> 
                            <a href={Resume} target ="_blank">here</a>
                        </p>
                    </Col>
                    <Col xs = {12} md = {6} xl = {2}>
                        <h2>{""}</h2>
                        <h2>{"魏"}</h2>
                        <h2>{"金"}</h2>
                        <h2>{"德"}</h2>
                    </Col>
                </Row>
                <Row>
                    <Col> </Col>
                    <Col xl={3}>
                        <img src={profileIcon} className="profile_img"/>
                    </Col>
                    <Col> </Col>
                    <Col> </Col>
                </Row>
            </Container>

        </section>
    )
}